'use client';

import { memo } from 'react';
import { Layout } from '@components/common';

function LayoutWrapper({ children }: React.PropsWithChildren) {
  return <Layout>{children}</Layout>;
}

const component = memo(LayoutWrapper);

component.displayName = 'LayoutWrapper';

export default component;
